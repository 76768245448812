import cardActionTypes from './cardActionTypes'

const InitialState = {
  loading: true,
  sending: false,
  formData: {
    // "status": "success",
    // "invoice_id": "string",
    // "payment_status": "new",
    // "signature": "string"
    // amount
    // currency
    // description
  },
  cardData: {},
  errors: {},
  fatalMessages: {},
  isReturn: false,
  returnUrl: undefined,
}

export default function cardReducer(state = InitialState, action) {
  const {type, payload} = action

  switch (type) {
    case cardActionTypes.SET_FATAL_MESSAGES: {
      return {
        ...state,
        fatalMessages: payload.fatalMessages ? [...payload.fatalMessages] : [],
      }
    }

    case cardActionTypes.INITIAL_REQUEST: {
      const {formData} = payload
      return {
        ...state,
        loading: true,
        formData: {
          ...state.formData,
          ...formData,
        },
        returnUrl: formData.internal_finish_url,
      }
    }

    case cardActionTypes.INITIAL_FULFILLED: {
      const {formData} = payload
      return {
        ...state,
        formData: {
          ...state.formData,
          ...formData,
        },
        loading: false,
      }
    }

    case cardActionTypes.INITIAL_FAILED: {
      return {
        ...state,
        loading: false,
        isReturn: payload.isReturn ? payload.isReturn : state.isReturn,
        returnUrl: payload.returnUrl ? payload.returnUrl : state.returnUrl,
      }
    }

    case cardActionTypes.PAY_REQUEST: {
      return {
        ...state,
        sending: true,
        cardData: payload.cardData,
        errors: {},
      }
    }

    case cardActionTypes.PAY_FULFILLED: {
      return {
        ...state,
        sending: false,
        errors: payload.errors,
      }
    }
    case cardActionTypes.PAY_FAILED: {
      return {
        ...state,
        sending: false,
        isReturn: payload.isReturn ? payload.isReturn : state.isReturn,
        returnUrl: payload.returnUrl ? payload.returnUrl : state.returnUrl,
      }
    }

    case cardActionTypes.SET_CARD_FIELD_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.field]: payload.value,
        },
      }
    }

    default: {
      return state
    }
  }
}
