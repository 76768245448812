import axios from 'axios'
import { call, put, takeEvery, all } from 'redux-saga/effects'
import finishActionTypes from './finishActionTypes'
import { setFatalMessages } from "./finishActions"
import posthog from 'posthog-js'
import { Environment } from "../../config/Environment";

const apiURL = Environment.REACT_APP_API_URL ?? ''

function* status({payload}) {
  const {data} = payload
  yield put(setFatalMessages([]))

  posthog?.capture('form_status', {
    $set: {
      form_status: data
    }
  })
  
  let response
  try {
    response = yield call(axios, {
      method: 'post',
      url: `${apiURL}/form/status`,
      data: {
        ...data,
      },
    })

    // if(response.data.success_url && response.data.payment_status === "complete") {
    //   formRedirect(response.data.success_url, "GET")
    //   return
    // }

    // if(response.data.error_url && response.data.payment_status === "failed") {
    //   formRedirect(response.data.error_url, "GET")
    //   return
    // }

    // if(response.data.finish_url) {
    //   formRedirect(response.data.finish_url, "GET")
    //   return
    // }

    yield put({
      type: finishActionTypes.STATUS_FULFILLED,
      payload: {
        status: response.data.payment_status,
        data: response.data,
      }
    })
  } catch (e) {
    response = e?.response
    yield put({
      type: finishActionTypes.STATUS_FAILED,
      payload: {
        status: "exception"
      }
    })
  } finally {
    if (response) {
      const status = response?.status
      const data = response?.data
      const event = `form_status_${status}`
      posthog?.capture(event, {
        $set: {
          [event]: {
            data,
            status
          }
        }
      })
    }
  }
}
export default function* finishSagas() {
  yield all([
    yield takeEvery(finishActionTypes.STATUS_REQUEST, status),
  ])
}
