import OpenSans from '../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf';
import { getFont } from './utils';

const OpenSansFont = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${OpenSans}) format('truetype')`,
};

const fonts = [OpenSansFont]

export const baseTheme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          '--font-family': getFont('Open Sans'),
        },
        '@font-face': [...fonts],
        body: undefined,
        html: undefined,
      },
    }
  }
}