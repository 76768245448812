import cardValidator from 'card-validator'
import { PAYMENT_FIELD_NAMES } from '../../constants'

export const validate = {
  intl: {},
  cardValid: false,
  cardMaxLength: 19,
  nameRequired: false,

  [PAYMENT_FIELD_NAMES.cardNumber](value, state, isActive) {
    let next = false
    const errors = []

    if (cardValidator.number(value).isValid) {
      next = PAYMENT_FIELD_NAMES.expDate
    }

    if (cardValidator.number(value).isPotentiallyValid && isActive) {
      return {
        fixedValue: value,
        errors,
        next,
      }
    }

    if (value.length === 0) {
      errors.push(validate.intl.formatMessage({id: 'card.error.pan.required'}))
    }
    if (!cardValidator.number(value).isValid) {
      errors.push(validate.intl.formatMessage({id: 'card.error.pan.invalid'}))
    }

    return {
      fixedValue: value,
      errors,
      next,
    }
  },

  [PAYMENT_FIELD_NAMES.nameoncard](value, state, isActive) {
    let next = false
    const errors = []

    if (this.nameRequired && value.length === 0) {
      errors.push(validate.intl.formatMessage({id: 'card.error.name.required'}))
    }

    if (value.length && !value.match(/^[a-zA-Z. -]{0,30}$/)) {
      errors.push(validate.intl.formatMessage({id: 'card.error.name.invalid'}))
    }
    return {
      fixedValue: value,
      errors,
    }
  },

  [PAYMENT_FIELD_NAMES.expDate]: (value, state, isActive) => {
    let next = false
    const errors = []

    let fixedValue = value.replace(/ /g, '').replace(/\//g, '')
    if (fixedValue.slice(2).length === 4) {
      fixedValue = `${fixedValue.slice(0, 2)}${fixedValue.slice(4)}`
    }
    const month = fixedValue.slice(0, 2)
    const year = fixedValue.slice(2)

    // if (cardValidator.expirationMonth(month).isPotentiallyValid && cardValidator.expirationYear(year).isPotentiallyValid && isActive) {
    //   return {
    //     fixedValue: value,
    //     errors,
    //   }
    // }

    if(year.length === 2 && cardValidator.expirationMonth(month).isValid ) {
      next = PAYMENT_FIELD_NAMES.cvv2
    }

    if (cardValidator.expirationMonth(month).isPotentiallyValid && isActive) {
      return {
        fixedValue: value,
        errors,
        next,
      }
    }

    if (fixedValue.length === 0) {
      errors.push(validate.intl.formatMessage({id: 'card.error.expiry.required'}))
    }

    if (!cardValidator.expirationMonth(month).isValid || month.length !== 2) {
      errors.push(validate.intl.formatMessage({id: 'card.error.expiry.month.invalid'}))
    }

    if (year.length !== 2) {
      errors.push(validate.intl.formatMessage({id: 'card.error.expiry.year.invalid'}))
    }
    // if (!cardValidator.expirationYear(year).isValid || year.length !== 2) {
    //   errors.push(validate.intl.formatMessage({id: 'card.error.expiry.year.invalid'}))
    // }

    return {
      fixedValue,
      errors,
      next
    }
  },

  [PAYMENT_FIELD_NAMES.cvv2](value, state, isActive) {
    let next = false
    const errors = []

    if (value.length >= 3) {
      next = PAYMENT_FIELD_NAMES.nameoncard
    }

    if (cardValidator.cvv(value).isPotentiallyValid && isActive) {
      return {
        fixedValue: value,
        errors,
        next,
      }
    }

    if (value.length === 0) {
      errors.push(validate.intl.formatMessage({id: 'card.error.cvc.required'}))
    }
    if (value.length < 3) {
      errors.push(validate.intl.formatMessage({id: 'card.error.cvc.invalid'}))
    }
    return {
      fixedValue: value,
      errors,
      next,
    }
  },

  captcha_text(value) {
    const errors = []
    if (value.length === 0) {
      errors.push(validate.intl.formatMessage({id: 'card.error.captcha_text.required'}))
    }
    return {
      fixedValue: value,
      errors,
    }
  },

}
