import {createStore, applyMiddleware, compose, combineReducers} from "redux"
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga.js'
import cardReducer from './cardSagas/cardReducer'
import finishReducer from "./finishSagas/finishReducer"

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  combineReducers({
    card: cardReducer,
    finish: finishReducer,
  }),
  compose(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

export default store
