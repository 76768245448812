import MaskedInput from 'react-text-mask'
import React from 'react'

export const CardMask = props => {
  const {inputRef, ...other} = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={[
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/,
      ]}
      guide={false}
      showMask
    />
  )
}