export const pt = {
  'order': 'Ordem {order}',
  'amount': '{amount}',

  'card.sample.expiry': 'MM / YY',
  'card.sample.name': 'PRIMEIRO NOME, ULTIMO NOME',

  'card.pan': 'Número do cartão',
  'card.owner': 'Nome e sobrenome como no cartão',
  'card.expiry': 'MM / YY',
  'card.cvc': 'CVC',
  'card.captcha_text': 'Insira os caracteres da imagem',

  'card.btn.pay': 'Pagar',

  'card.error.pan.required': 'Preencha o campo.',
  'card.error.pan.invalid': 'Número de cartão inválido.',
  'card.error.name.invalid': 'Precisamos disso. símbolos, espaços, ponto, traço (até 30).',
  'card.error.name.required': 'Preencha o campo.',
  'card.error.cvc.required': 'Preencha o campo.',
  'card.error.cvc.invalid': 'Preencha o campo.',
  'card.error.expiry.required': 'Preencha o campo.',
  'card.error.expiry.month.invalid': 'Valor de 01 a 12.',
  'card.error.expiry.year.invalid': 'Ano atual ou próximo.',
  'card.error.captcha_text.required': 'Preencha o campo.',

  'card.protected.caption.title': 'Seus dados estão protegidos',
  'card.protected.caption.desc': 'Os dados do cartão bancário são transmitidos através de protocolos seguros e não chegarão à loja online ou a terceiros. Os pagamentos são processados ​​em uma página de processamento segura de acordo com o padrão PCI DSS',

  'card.sending.preloader.title': 'Não feche a janela nem atualize a página',
  'card.sending.preloader.desc': 'Estamos criando um link. Isso pode levar de alguns segundos a um minuto.',

  'fatal_errors': 'Motivo da rejeição:',

  'card.processing.1': 'Preparação de dados',
  'card.processing.2': 'Verificando opções de pagamento',
  'card.processing.3': 'Fazendo um pagamento',

  'finish.pending': 'Processo de pagamento',
  'finish.pending.desc': 'Seu pagamento foi aceito para processamento. Feche esta janela e volte para a loja.',
  'finish.complete': 'Pagamento aceito',
  'finish.complete.desc': 'O pagamento foi bem-sucedido. Feche esta janela e volte para a loja.',
  // 'finish.failed': 'Ocorreu um erro ao processar o pagamento.',
  'finish.failed': 'Pagamento Recusado',
  'finish.failed.desc': 'Por favor, retorne à loja para pagar novamente.',
  'finish.refunded': 'Pagamento devolvido',
  'finish.refunded.desc': ' ',
  'finish.exception': 'Algo deu errado',
  'finish.exception.desc': ' ',

  'finish.debug_info': 'Informações para entrar em contato com o suporte',
  'return.btn.return': 'Voltar para a loja',
}
