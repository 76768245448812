import React from 'react'
import { CardPage } from './pages/CardPage/CardPage'
import { NspkQrPage } from './pages/NspkQrPage/NspkQrPage'
import { BlikPage } from './pages/BlikPage/BlikPage.js'
import store from './model/store'
import {fixLanguage, lang} from './lang/lang'
import { IntlProvider } from 'react-intl'
import { Provider, useSelector } from 'react-redux'
// import { init as initApm } from '@elastic/apm-rum'
// import { PostHogProvider } from 'posthog-js/react'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import { FinishPage } from "./pages/FinishPage/FinishPage"
import "text-security/text-security.css";
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getThemeSettings } from './themes/themes'
import {Environment} from './config/Environment'

// import { tracker } from "./tracker";

// initApm({
//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: 'h2h-payment-widget',
//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: 'https://c30bbd544e1d4118bb9c2570f7835660.apm.europe-west3.gcp.cloud.es.io:443',
//   // Set service version (required for sourcemap feature)
//   serviceVersion: ''
// })

// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// }

const theme = createMuiTheme(getThemeSettings(process.env.REACT_APP_THEME));

const currentURL = new URL(window.location)

const currentPathname = currentURL.pathname
const basename = currentPathname
  .replace('init', '')
  .replace('finish', '')
  .replace('nspk-qr', '')
  .replace('blik', '')

const router = createBrowserRouter([
  {
    path: "/finish",
    element: <FinishPage/>,
  },
  {
    path: "/init",
    element: <CardPage/>,
  },
  {
    path: "/nspk-qr",
    element: <NspkQrPage/>,
  },
  {
    path: "/blik",
    element: <BlikPage/>,
  },  
], {
  basename,
});

function LanguageSwitcher() {
  const urlParamsObj = new URLSearchParams(window.location.search);
  const urlParams = {}

  for (const key of urlParamsObj.keys()) {
      urlParams[key] = urlParamsObj.get(key)
  }

  const card = useSelector(state => state.card)
  const language = fixLanguage(card.formData.language || urlParams['language'] || Environment.REACT_APP_DEFAULT_LANGUAGE || 'ru')

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider
          locale={language}
          messages={lang[language]}
          defaultLocale="ru"
        >
          <RouterProvider router={router} />
          {/*<PostHogProvider*/}
          {/*  apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}*/}
          {/*  options={options}*/}
          {/*>*/}
          {/*  <RouterProvider router={router}/>*/}
          {/*</PostHogProvider>*/}
        </IntlProvider>
      </ThemeProvider>
    </div>
  )
}

function App() {

  // useEffect(() => {
  //   tracker.start();
  // }, [])

  return (
    <div className="App">
      <Provider store={store}>
        <LanguageSwitcher/>
      </Provider>
    </div>
  )
}

export default App
