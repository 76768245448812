export const hu = {
  'order': 'Rendelés {order}',
  'amount': '{amount}',

  'card.sample.expiry': 'HH / ÉÉ',
  'card.sample.name': 'KERESZTNÉV CSALÁDNÉV',

  'card.pan': 'Kártyaszám',
  'card.owner': 'Utó- és vezetéknév, mint a kártyán',
  'card.expiry': 'HH / ÉÉ',
  'card.cvc': 'CVC',
  'card.captcha_text': 'Írja be a képen látható karaktereket',

  'card.btn.pay': 'Fizetés',

  'card.error.pan.required': 'Töltse ki a mezőt.',
  'card.error.pan.invalid': 'Érvénytelen kártyaszám.',
  'card.error.name.invalid': 'Lat kell. szimbólumok, szóközök, pontok, kötőjelek (legfeljebb 30).',
  'card.error.name.required': 'Töltse ki a mezőt.',
  'card.error.cvc.required': 'Töltse ki a mezőt.',
  'card.error.cvc.invalid': 'Töltse ki a mezőt.',
  'card.error.expiry.required': 'Töltse ki a mezőt.',
  'card.error.expiry.month.invalid': '01 és 12 közötti érték.',
  'card.error.expiry.year.invalid': 'A jelenlegi vagy a következő években.',
  'card.error.captcha_text.required': 'Töltse ki a mezőt.',

  'card.protected.caption.title': 'Adatai védettek',
  'card.protected.caption.desc': 'A bankkártya adatai biztonságos protokollon keresztül kerülnek továbbításra, és nem jutnak el az online áruházhoz vagy harmadik felekhez. A kifizetések feldolgozása a PCI DSS szabványnak megfelelő biztonságos feldolgozási oldalon történik',

  'card.sending.preloader.title': 'Ne zárja be az ablakot és ne frissítse az oldalt',
  'card.sending.preloader.desc': 'Linket hozunk létre. Ez néhány másodperctől egy percig is eltarthat.',

  'fatal_errors': 'Elutasítás oka:',

  'card.processing.1': 'Adatok előkészítése',
  'card.processing.2': 'Fizetési lehetőségek ellenőrzése',
  'card.processing.3': 'Befizetés',

  'finish.pending': 'Fizetés feldolgozása',
  'finish.pending.desc': 'Befizetését elfogadtuk feldolgozásra. Zárja be ezt az ablakot, és térjen vissza az üzletbe.',
  'finish.complete': 'Fizetés elfogadva',
  'finish.complete.desc': 'A fizetés sikeres volt. Zárja be ezt az ablakot, és térjen vissza az üzletbe.',
  // 'finish.failed': 'Hiba történt a fizetés feldolgozása közben.',
  'finish.failed': 'Fizetés elutasítva',
  'finish.failed.desc': 'Kérjük, térjen vissza az üzletbe az újrafizetéshez.',
  'finish.refunded': 'Fizetés vissza',
  'finish.refunded.desc': ' ',
  'finish.exception': 'Valami elromlott',
  'finish.exception.desc': ' ',

  'finish.debug_info': 'Információk a támogatással való kapcsolatfelvételhez',
  'return.btn.return': 'Vissza a boltba',
}
