import finishActionTypes from './finishActionTypes'
import cardActionTypes from "../cardSagas/cardActionTypes";

export function setFatalMessages(fatalMessages) {
  return {
    type: cardActionTypes.SET_FATAL_MESSAGES,
    payload: {fatalMessages}
  }
}

export function status(data) {
  return {
    type: finishActionTypes.STATUS_REQUEST,
    payload: {data}
  }
}
