export function formRedirect(redirect, method, data) {
  const form = document.createElement("form")
  form.style.display = 'none'

  const url = new URL(redirect)
  url.searchParams.forEach((value, key) => {
    const el = document.createElement("input")
    el.value = value
    el.name = key
    form.appendChild(el)
  })

  if(data) {
    Object.entries(data).forEach(([key, value]) => {
      const el = document.createElement("input")
      el.value = String(value)
      el.name = key
      form.appendChild(el)
    })
  }

  form.method = method
  form.action = redirect
  document.body.appendChild(form)

  form.submit()
}
