import React, { useEffect } from 'react'
import styles from './CardPage.module.scss'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import { PaymentForm } from '../../components/PaymentForm/PaymentForm'
import { useSelector } from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress"
import store from "../../model/store"
import { ReturnForm } from "../../components/RetrunForm/ReturnForm"
import { PaymentHeader } from "../../components/PaymentHeader/PaymentHeader"
import { usePostHog } from 'posthog-js/react'

export const CardPage = () => {
  const posthog = usePostHog();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const data = {}

    for (const key of urlParams.keys()) {
      data[key] = urlParams.get(key)
    }

    store.dispatch({
      type: 'INITIAL_REQUEST',
      payload: {
        formData: {
          ...data,
          // "invoice_id": urlParams.get('invoice_id'),
          // product_id: urlParams.get('product_id'),
          // customer_id: urlParams.get('customer_id'),
          // merchant_id: urlParams.get('merchant_id'),
          // extra_data: urlParams.get('extra_data'),
          // order: urlParams.get('order'),
          // endpoint_id: urlParams.get('endpoint_id'),
          // signature: urlParams.get('signature'),
          // language: urlParams.get('language') ?? 'en',
          // currency: urlParams.get('currency'),
          // amount: urlParams.get('amount'),
          // description: urlParams.get('description'), // ???
        }
      }
    })

  }, [])

  const card = useSelector(state => state.card)
  const {formData, fatalMessages, isReturn, returnUrl} = card
  const {amount, currency, order, description} = formData

  useEffect(() => {
    if (posthog) {
      const phData = {
        amount: formData?.amount,
        currency: formData?.currency,
        language: formData?.language,
        merchant_id: formData?.merchant_id,
        endpoint_id: formData?.endpoint_id,
        signature: formData?.signature,
        order: formData?.order,
        form_version: process.env.REACT_APP_VERSION,
      }
      posthog?.identify(formData?.order, phData)
    }
  }, [posthog])

  if (card.loading) {
    return (
      <div>
        <div className={styles.preloaderWrapper}>
          <CircularProgress
            className={styles.preloader}
            size={120}
            thickness={3}
          />
          {/*<div className={styles.preloaderText}>*/}
          {/*  {loadingTexts[Math.min(loadingTextIndex, loadingTexts.length - 1)]}*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Card
        elevation={1}
        className={styles.page}
      >
        <CardMedia>
          <PaymentHeader
            order={order}
            description={description}
            amount={amount}
            currency={currency}
          />
        </CardMedia>

        {isReturn ? (
          <CardContent className={styles.content}>
            <ReturnForm
              fatalMessages={fatalMessages}
              url={returnUrl}
            />
          </CardContent>
        ): (
          <CardContent className={styles.content}>
            <PaymentForm
              formData={formData}
              fatalMessages={fatalMessages}
            />
          </CardContent>
        )}
      </Card>
    </div>
  )
}
