import { lightTheme } from './lightTheme'
import { baseTheme } from './baseTheme'

export const getThemeSettings = (theme) => {
  switch (theme) {
    case 'light':
      return lightTheme
    default:
      return baseTheme
  }
}
