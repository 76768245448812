import {ru} from './ru'
import {en} from './en'
import {Environment} from '../config/Environment'
import {hu} from "./hu"
import {pt} from "./pt"

export const lang = {
  ru,
  en,
  hu,
  pt,
}

export function fixLanguage(str) {
  const tag = str.toLocaleString()
  if(lang[tag]) {
    return tag
  }
  return Environment.REACT_APP_DEFAULT_LANGUAGE || 'en'
}
