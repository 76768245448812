export const en = {
  'order': 'Order {order}',
  'amount': '{amount}',

  'card.sample.expiry': 'valid thru',
  'card.sample.name': 'YOUR NAME HERE',

  'card.pan': 'Card number',
  'card.owner': 'Cardholder name',
  'card.expiry': 'Expiry date',
  'card.cvc': 'CVC',
  'card.captcha_text': 'Enter the characters from the picture',

  'card.btn.pay': 'Pay',

  'card.error.pan.required': 'Fill the field.',
  'card.error.pan.invalid': 'Invalid card number.',
  'card.error.name.invalid': 'Invalid characters.',
  'card.error.name.required': 'Fill the field.',
  'card.error.cvc.required': 'Fill the field.',
  'card.error.cvc.invalid': 'Fill the field.',
  'card.error.expiry.required': 'Fill the field.',
  'card.error.expiry.month.invalid': 'Value from 01 to 12.',
  'card.error.expiry.year.invalid': 'Current or next years.',
  'card.error.captcha_text.required': 'Fill the field.',

  'card.protected.caption.title': 'Your data is protected',
  'card.protected.caption.desc': 'Bank card details are transmitted using secure protocols and will not be sent to the online store and third parties. persons. Payments are processed on a secure processing page according to the PCI DSS standard',

  'card.sending.preloader.title': 'Don\'t close the window or refresh the page',
  'card.sending.preloader.desc': 'We are building a link. This may take from a few seconds to a minute.',

  'fatal_errors': 'Rejection reason:',

  'card.processing.1': 'Data preparation',
  'card.processing.2': 'Checking the possibility of payment',
  'card.processing.3': 'Payment processing',

  'finish.pending': 'Thank you! Your payment processing',
  'finish.pending.desc': ' ',
  'finish.complete': 'Payment was successful',
  'finish.complete.desc': ' ',
  // 'finish.failed': 'An error occurred while processing the payment.',
  'finish.failed': 'Payment rejected',
  'finish.failed.desc': ' ',
  'finish.refunded': 'Payment refunded',
  'finish.refunded.desc': ' ',
  'finish.exception': 'Something went wrong',
  'finish.exception.desc': ' ',

  'finish.debug_info': 'Support Information',

  'return.btn.return': 'Back to shop',
}
