const cardActionTypes = {
  INITIAL_REQUEST: 'INITIAL_REQUEST',
  INITIAL_FULFILLED: 'INITIAL_FULFILLED',
  INITIAL_FAILED: 'INITIAL_FAILED',

  PAY_REQUEST: 'PAY_REQUEST',
  PAY_FULFILLED: 'PAY_FULFILLED',
  PAY_FAILED: 'PAY_FAILED',

  SET_CARD_FIELD_ERROR: 'SET_CARD_FIELD_ERROR',
  SET_FATAL_MESSAGES: 'SET_FATAL_MESSAGES',
}

export default cardActionTypes
