import cardSagas from './cardSagas/cardSagas'
import {all} from '@redux-saga/core/effects'
import finishSagas from "./finishSagas/finishSagas"

export default function* rootSaga() {
  yield all([
    cardSagas(),
    finishSagas(),
  ])
}
