import React from 'react'
import {useIntl} from 'react-intl'
import styles from './ErrorMessage.module.scss'

export const ErrorMessage = props => {
  const {errors} = props
  const intl = useIntl()

  return (
    <div>
      <div className={styles.title}>
        {intl.formatMessage({id: 'fatal_errors'})}
      </div>
      <div className={styles.error}>
        {errors.join(' ')}
      </div>
    </div>
  )
}
