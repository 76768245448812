import cardActionTypes from './cardActionTypes'

export function setFatalMessages(fatalMessages) {
  return {
    type: cardActionTypes.SET_FATAL_MESSAGES,
    payload: {fatalMessages}
  }
}

export function setCardFieldError(field, value) {
  return {
    type: cardActionTypes.SET_CARD_FIELD_ERROR,
    payload: {field, value}
  }
}

export function pay(cardData) {
  return {
    type: cardActionTypes.PAY_REQUEST,
    payload: {cardData}
  }
}
