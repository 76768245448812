import React from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import styles from './PaymentHeader.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { beautifyCurrency } from "../../curreny";

export const PaymentHeader = props => {
  const {order, description, amount, currency} = props

  return (
    <div className={styles.header}>
      <div className={styles.order}>
        {order && <FormattedMessage id="order" values={{order}}/>}
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {(amount && currency) && (
        <div className={styles.amount}>
          <FormattedMessage id="amount" values={{amount}}/>
          {" "}
          <span className={styles.currency}>{beautifyCurrency(currency)}</span>
        </div>
      )}
    </div>
  )
}
