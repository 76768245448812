
export const Environment = {
  ...process.env,
}

Object.keys(Environment).forEach(key => {
  if(Environment[key] === `__${key}__`) {
    delete Environment[key]
  }
})
