import MaskedInput from 'react-text-mask'
import React from 'react'

export const ExpiryMask = props => {
  const {inputRef, ...other} = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={props.value ? [/\d/, /\d/, '/', /\d/, /\d/] : [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={!!(props.value.replace(/ /g, '').replace(/\//g, ''))}
      placeholderChar={' '}
      showMask
    />
  )
}