import TTNormsProRegular from '../assets/fonts/TTNormsPro/TTNormsProRegular.woff2';
import TTNormsProMedium from '../assets/fonts/TTNormsPro/TTNormsProMedium.woff2';
import TTNormsProBold from '../assets/fonts/TTNormsPro/TTNormsProBold.woff2';
import { getFont } from './utils';

const TTNormsProRegularFont = {
  fontFamily: 'TTNormsPro',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${TTNormsProRegular}) format("woff2")`,
};

const TTNormsProMediumFont = {
  fontFamily: 'TTNormsPro',
  fontWeight: 500,
  fontStyle: 'normal',
  src: `url(${TTNormsProMedium}) format("woff2")`,
};

const TTNormsProBoldFont = {
  fontFamily: 'TTNormsPro',
  fontWeight: 700,
  fontStyle: 'normal',
  src: `url(${TTNormsProBold}) format("woff2")`,
};

const fonts = [TTNormsProRegularFont, TTNormsProMediumFont, TTNormsProBoldFont];

export const lightTheme = {
  palette: {
    primary: {
      main: '#FF8800',
      dark: '#dc7602',
      light: 'rgb(255, 136, 0, 0.4)',
    },
    error: {
      main: '#FF704D'
    },
    success: {
      main: '#FF8800'
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          '--color-primary': '#FF8800',
          '--color-primary-dark': '#dc7602',
          '--color-white': '#ffffff',
          '--color-success': '#FF8800',
          '--color-error': '#FF704D',
          '--color-warning': '#bb750c',
          '--color-common-1': '#999999',
          '--color-common-2': '#333333',
          '--color-common-3': '#E8E8E8',
          '--color-common-4': '#CCCCCC',
          '--color-common-5': '#222222',
          '--font-family': getFont('TTNormsPro'),
        },
        '@font-face': [...fonts],
        body: undefined,
        html: undefined,
      },
    },
    MuiInputBase: {
      root: {
        "&.MuiInput-underline:before": {
          borderBottom: "1px solid var(--color-common-3)"
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid var(--color-common-3)"
        },
      }
    },
    MuiInputLabel: {
      root: {
        color: 'var(--color-common-1)'
      }
    },
  },
}